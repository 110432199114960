<template>
  <div id="NewUpdateLog" style="height: calc(100% - 0px); padding-left: 10px">
    <div style="background: #ffffff" class="total_table_body">
      <div class="heder">
        <div class="heder_left">
          <el-button
            style="margin-left: 10px"
            class="internalButton"
            @click="newlyBuild()"
            type="primary"
            size="small"
            >新建</el-button
          >
          <el-button
            type="primary"
            class="internalButton"
            size="small"
            @click="monitorEdit()"
            >编辑</el-button
          >
          <el-button
            style="margin-left: 10px"
            class="internalButton"
            @click="monitorDeletion()"
            type="primary"
            size="small"
            >删除</el-button
          >
        </div>
        <div class="heder_right">
          <div style="display: flex">
            <el-input
              oninput="value=value.replace(/^\s+|\s+$/gm,'')"
              v-show="!Show"
              maxlength="128"
              size="small"
              class="el_input"
              v-model="input"
              placeholder="请输入查询内容"
              @keyup.enter.native="sousou()"
            ></el-input>
            <el-button
              v-show="!Show"
              class="show_button"
              type="primary"
              size="small"
              icon="el-icon-search"
              @click="sousou"
            ></el-button>
          </div>
        </div>
      </div>
      <div style="margin: 0 10px" class="table_body">
        <el-table
          ref="singleTable"
          v-loading="loading"
          border
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#e0e4ec', color: '#000' }"
          :row-style="rowClass"
          highlight-current-row
        >
          <el-table-column type="selection" width="45"> </el-table-column>
          <el-table-column
            prop="upgradeDate"
            label="升级日期"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="upgradeContent"
            label="升级内容"
          ></el-table-column>
        </el-table>
        <div
          style="
            position: relative;
            width: 100%;
            background-color: white;
            height: 34px;
          "
        >
          <el-pagination
            style="position: absolute; right: 0"
            @size-change="handleSizeChange"
            @current-change="currentChange"
            :page-sizes="[20, 40, 60]"
            :page-size="pagesize"
            :hide-on-single-page="false"
            ref="pagetotal"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagetotal"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-drawer
      title="我是标题"
      :modal="false"
      :visible.sync="addDrawer"
      size="100%"
      :with-header="false"
    >
      <div style="height: 65px" class="add_head">
        <p class="add_title">{{ createAndModify }} 更新日志</p>
        <div class="head_close">
          <el-button
            type="primary"
            class="preservation"
            size="small"
            @click="newChargingPile('chargeList')"
          >
            保存</el-button
          >
          <p
            class="el-icon-close close_icon"
            title="关闭"
            @click="chargingPointClosed('chargeList')"
          ></p>
        </div>
      </div>
      <el-col :span="8" class="add_card_col">
        <div class="add_rightbody_two">
          <el-card
            class="el_add_card_hover"
            style="position: relative"
            id="card_2"
            shadow="hover"
          >
            <el-form
              :rules="rules_add"
              :model="chargeList"
              ref="chargeList"
              label-width="155px"
              label-position="left"
              size="small"
              class="el_form_body"
            >
              <div class="form_body_box" style="width: 100%">
                <el-form-item
                  label="升级日期"
                  style="width: 50%"
                  prop="upgradeDate"
                >
                  <el-date-picker
                    v-model="chargeList.upgradeDate"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="form_body_box" style="width: 100%">
                <el-form-item
                  label="升级内容"
                  prop="upgradeContent"
                  style="width: 100%"
                >
                  <el-input
                    type="textarea"
                    :rows="25"
                    placeholder="请输入内容"
                    v-model="chargeList.upgradeContent"
                  >
                  </el-input>
                </el-form-item>
              </div>
            </el-form>
            <div class="message_box_mini"></div>
          </el-card>
        </div>
      </el-col>
    </el-drawer>
  </div>
</template>
<script>
export default {
  name: "NewUpdateLog",
  data() {
    return {
      chargeList: {
        upgradeContent: "",
        upgradeDate: "",
      },
      addDrawer: false,
      detailsPopup: false,
      verificationFrame: false,
      detailsData: "",
      subsidiaryList: [],
      rules_add: {
        upgradeContent: [{ required: true, message: "请输入升级内容" }],
        upgradeDate: [{ required: true, message: "请选中升级日期" }],
      },
      loading: true,
      addDrawer: false,
      size: "60%",
      input: "",
      add_icon: "iconfont icon-suofang",
      tableData: [],
      Show: false,
      selectData: [],
      selectRow: [],
      drawer: false,
      pagesize: 20, //每页数据条数
      pagetotal: 1, //总条数
      currentPage: 1, //分页选项第几页,
      createAndModify: "新建",
      file: "",
      sortedValue: [
        {
          sortField: "createTime",
          direction: "DESC",
        },
      ],
    };
  },

  computed: {
    dataDictionary() {
      return this.$store.state.dataDictionary;
    },
  },
  methods: {
    newChargingPile(chargeList) {
      if (this.createAndModify == "新建") {
        this.$refs[chargeList].validate((valid) => {
          if (valid == true) {
            this.api
              .postFormAPISM(
                this.chargeList,
                "/v1.0/uap/data_service/functionUpdate/addFunctionUpdate"
              )
              .then((res) => {
                this.addDrawer = false;
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                this.queryDelegation();
              })
              .catch((err) => console.log(err));
          }
        });
      } else {
        this.$refs[chargeList].validate((valid) => {
          if (valid == true) {
            this.api
              .postFormAPISM(
                this.chargeList,
                "/v1.0/uap/data_service/functionUpdate/updateFunctionUpdate",
                {
                  id: this.chargeList.id,
                }
              )
              .then((res) => {
                this.addDrawer = false;
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.queryDelegation();
              })
              .catch((err) => console.log(err));
          }
        });
      }
    }, // 新建充电桩
    chargingPointClosed(chargeList) {
      this.$refs[chargeList].resetFields();
      this.chargeList = {
        upgradeContent: "",
        // upgradeDate: new Date(),
      };
      this.addDrawer = false;
    }, // 关闭充电桩
    newVerification() {
      this.createAndModify = "新建";
      this.verificationFrame = true;
    }, //新建检定证书
    newlyBuild() {
      this.createAndModify = "新建";
      this.addDrawer = true;
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const date = currentDate.getDate();
      const end = new Date(year, month, date, 0, 0, 0).getTime(); // 2021-12-24 00:00:00
      this.chargeList = {
        upgradeContent: "",
        upgradeDate: year + "-" + month + "-" + date,
      };
      console.log(year, month, date);
    },
    monitorEdit() {
      if (this.selectData.length == 0) {
        this.$message({
          message: "请勾选后编辑",
          type: "warning",
        });
      } else {
        this.api
          .getListAPIUAP(
            { id: this.selectData[0].id },
            "/v1.0/uap/data_service/functionUpdate/viewFunctionUpdate"
          )
          .then((res) => {
            this.addDrawer = true;
            this.createAndModify = "编辑";
            this.chargeList = res.data.data;
          })
          .catch((err) => console.log(err));
      }
    },
    monitorDeletion() {
      if (this.selectData.length == 0) {
        this.$message({
          message: "请勾选后删除",
          type: "warning",
        });
      } else {
        this.$confirm("是否删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          confirmButtonClass: "butText",
          type: "warning",
        })
          .then(() => {
            var stationInfoIdList = [];
            this.selectData.forEach((item) => {
              stationInfoIdList.push(item.id);
            });
            this.api
              .getListAPIUAP(
                { ids: stationInfoIdList.join() },
                "/v1.0/uap/data_service/functionUpdate/deleteFunctionUpdate"
              )
              .then((res) => {
                if (res.data.return_code == "LAB-0000000") {
                  this.$message({
                    message: "删除成功",
                    type: "success",
                  });
                  this.queryDelegation();
                } else {
                }
              })
              .catch((err) => console.log(err));
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    handleSizeChange(pagesize) {
      this.pagesize = pagesize;
      this.queryDelegation();
    },
    currentChange: function (currentPage) {
      this.currentPage = currentPage;
      this.queryDelegation();
    },
    sousou() {
      this.currentPage = "1";
      this.pagetotal = 0;
      this.loading = true;
      var data = {
        queryInfo: {
          pageNum: this.currentPage,
          pageSize: this.pagesize,
          filters: [
            {
              fieldName: "upgradeContent",
              operator: "LIKE",
              fieldValue: this.input,
              connectOperator: "or",
            },
          ],
          sorters: this.sortedValue,
          statisticsDateParam: this.$route.query.entrustState,
          connectOperator: "OR",
        },
      };
      this.api
        .postFormAPIUAP(data, "/v1.0/uap/data_service/functionUpdate/queryAll")
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.data.list;
          this.pagetotal = res.data.data.total;
        })
        .catch((err) => console.log(err));
    },

    queryDelegation() {
      this.loading = true;
      var data = {
        queryInfo: {
          pageNum: this.currentPage,
          pageSize: this.pagesize,
          filters: [],
          sorters: this.sortedValue,
        },
      };
      this.api
        .postFormAPIUAP(data, "/v1.0/uap/data_service/functionUpdate/queryAll")
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.data.list;
          this.pagetotal = res.data.data.total;
        })
        .catch((err) => console.log(err));
    },

    postForm() {},
    handleSelectionChange(data) {
      this.selectData = data;
    },
    rowClass({ row, rowIndex }) {
      if (this.selectRow.includes(rowIndex)) {
        return { "background-color": "#ECF5FF" };
      }
    },
    Resize() {
      var Height = $(window).height() - 180;
      $(".public_main").css({ height: Height });
    },
  },
  mounted() {
    this.sousou();
    $(window).resize(function () {
      var Height = document.documentElement.clientHeight - 180;
      $(".public_main").css({ height: Height });
    });
  },
  watch: {},
};
</script>
<style lang="less" scoped>
/deep/.el-tabs__nav_top {
  .el-tabs__active-bar {
    width: 57px !important;
  }
}
/deep/ .el-input__inner {
  font-size: 12px !important;
}
/deep/ .el-date-editor {
  width: 100% !important;
}
.selected {
  font-weight: 300 !important;
}
.el_dialong {
  /deep/.el-dialog--center {
    min-width: 500px !important;
  }
  .warn-tips {
    background-color: #fffbdb;
  }
  .ic {
    width: 12px;
    height: 16px;
    top: 6px;
    left: 16px;
    background-position: 0 -20px;
    display: block;
    position: absolute;
    svg {
      width: 19px;
      height: 26px;
    }
  }
  .tips {
    line-height: 20px;
    padding: 5px 20px 4px 39px;
    color: #999;
    margin-bottom: 20px;
    position: relative;
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.reason-list {
  padding: 0 35px 0 35px;
  // padding: 0 15px 0 35px;
  overflow: hidden;
  margin-bottom: 10px;
  .reasonBody {
    height: 38px;
    float: left;
    // padding-left: 20px;
    margin-bottom: 18px;
    position: relative;

    .reason {
      background: #fff;
      width: 176px;
      height: 34px;
      border: 1px solid #ddd;
      margin-right: 15px;
      margin-bottom: 15px;
      float: left;
      color: #666;
      line-height: 34px;
      text-align: center;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      i {
        display: none;
        width: 15px;
        height: 15px;
        background: #02b980;
        position: absolute;
        right: -2px;
        bottom: -2px;
        svg {
          width: 15px;
          height: 15px;
          position: absolute;
          right: 0;
        }
      }
    }
    .reason:hover {
      border: 2px solid #02b980;
    }
    .selected {
      // width: 196px;
      // height: 32px;
      // line-height: 32px;
      border: 2px solid #02b980;
    }
    .selected i {
      display: block;
    }
  }
}

#NewUpdateLog {
  .top_heder {
    padding-top: 50px;
    padding-bottom: 50px;
    .top_heder_box {
      display: flex;
      justify-content: space-between;
      width: 40%;
      margin: 0 auto;
      padding-top: 10px;
      .top_heder_box_left {
        display: flex;
        width: 40%;
        span {
          font-size: 12px;
          width: 120px;
          line-height: 28px;
          text-align: right;
        }
      }
      .top_heder_box_right {
        display: flex;
        width: 40%;
        span {
          font-size: 12px;
          width: 120px;
          line-height: 28px;
          text-align: right;
        }
      }
    }
    .top_heder_box2 {
      width: 0%;
      margin: 0 auto;
      padding-top: 20px;
    }
  }
  .ppl {
    line-height: 30px;
    padding-left: 20px;
    margin-top: 2px;
    width: 100%;
    min-width: 316.34px !important;
    span {
      line-height: 24px;
      padding: 7px 8px 7px 8px !important;
      cursor: pointer;
      border-radius: 4px;
    }
  }
  .sp {
    color: #0f7c4f !important;
  }
  .fxspan {
    line-height: 30px;
    font-size: 17px;
    margin-left: 10px;
    color: #898a8b !important;
    .heder_two {
      display: flex;
      margin-left: 5px;
      padding-bottom: 5px;
      padding-top: 20px;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      .heder_left {
        display: flex;
        height: 32px;
        rgin-left: 10px;
      }
    }
    .heder_right {
      padding-right: 20px;
      display: flex;
      /deep/ .el-button {
        margin-right: 20px;
      }
    }
  }
  /deep/ .el-button--text {
    color: #525252;
    font-size: 12px;
  }
  /deep/ .el-table td {
    padding: 6px 0;
    font-size: 12px;
  }
  /deep/ .cell {
    .el-button {
      color: #143852;
      padding: 0px;
    }
  }
  /deep/.el_input .el-input__inner {
    width: 300px;
    border-radius: 4px 0 0 4px !important;
    font-size: 13px !important;
  }
  /deep/.show_button {
    border-radius: 0 4px 4px 0;
    height: 32px;
  }
  /deep/.el-input__inner:focus {
    border: 1px solid #0a8a55;
  }
  /deep/.el-input__inner:hover {
    border: 1px solid #0a8a55;
  }
  /deep/.el-textarea__inner:focus {
    border: 1px solid #0a8a55;
  }
  /deep/.el-textarea__inner:hover {
    border: 1px solid #0a8a55;
  }
  /deep/.el-button--success {
    background: rgb(10, 138, 85);
    border: none;
  }
  /deep/.el-input {
    font-size: 15px;
  }
  /deep/.embedded_input {
    font-size: 12px !important;
  }
  /deep/.el-form-item {
    margin-top: 18px;
  }
  /deep/.el-textarea__inner {
    font-size: 15px;
  }
  /deep/.el-table__header-wrapper {
    background: rgb(233, 238, 245);
  }
  /deep/.el-radio-group {
    float: left;
    width: 100%;
    line-height: 36px;
  }
  // /deep/.el-table__row:active {
  //   background: rgba(0, 86, 80, 0.774);
  // }
  /deep/.el-card__header {
    padding: 20px 20px 8px 20px;
    border: none;
  }
  /deep/.el-card__body {
    padding: 5px;
  }
  /deep/.el-drawer {
    background: rgb(242, 242, 245);
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: rgb(10, 138, 85);
    border-color: rgb(10, 138, 85);
  }
  /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: rgb(10, 138, 85);
    border-color: rgb(10, 138, 85);
  }
  /deep/.el-tabs__item.is-active {
    color: #005650 !important;
  }
  /deep/.el-tabs__item {
    padding: 0 10px;
  }
  /deep/.el-tabs__active-bar {
    background-color: #005650;
  }
  /deep/.el-tabs__item:hover {
    color: #000;
  }
  .page_switch {
    margin-left: 10px !important;
    width: 330px !important;
  }
  .page_switch_child {
    width: 330px !important;
  }
  .page_switch_two {
    width: 330px !important;
    margin-left: 1px !important;
  }
  // /deep/.aaa .el-table__header-wrapper{
  //   position: sticky;
  //   top: 0px;
  //   z-index: 9999;
  // }
  /deep/.el-tabs__nav-prev {
    background-color: #f0f0f0;
    width: 15px;
    z-index: 99;
  }
  /deep/.el-tabs__nav-prev:hover {
    background-color: #0a8a5557;
  }
  /deep/.el-tabs__nav-next {
    background-color: #f0f0f0;
    width: 15px;
    z-index: 99;
  }
  /deep/.el-tabs__nav-next:hover {
    background-color: #0a8a5557;
  }
  /deep/.el-icon-arrow-left {
    color: #0a8a55;
    font-weight: 600;
  }
  /deep/.el-icon-arrow-right {
    color: #0a8a55;
    font-weight: 600;
    position: relative;
    top: -2px;
    left: 1px;
  }
  /deep/.el-icon-arrow-left {
    color: #0a8a55;
    font-weight: 600;
    position: relative;
    top: -2px;
    left: 1px;
  }

  .el-tabs__nav_top {
    margin-left: 10px;
    margin-top: 5px;
  }
  .el-select-dropdown__item.selected:focus {
    color: #005650 !important;
  }
  .el-button--small {
    padding: 9px 20px;
  }
  .message_box_big {
    width: 100%;
    height: 900px;
  }
  .message_box_small {
    width: 100%;
    height: 500px;
    float: left;
  }
  .message_box_mini {
    width: 100%;
    height: 300px;
    float: left;
  }
  .details_one {
    width: 100%;
  }
  .details_one_ul {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 2%;
    li {
      width: 99%;
      line-height: 22px;
      padding-bottom: 2px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      margin-right: 1%;
      h2 {
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        margin-bottom: 4px;
      }
      p {
        width: 50%;
        padding-top: 15px;
        display: flex;
        justify-content: space-between;
        color: #585858;
        border-bottom: 1px solid #f0f0f0;
        .details_one_ul_span {
          width: 30%;
          height: 100%;
          display: inline-block;
        }
        .details_one_ul_spanr {
          color: #6e6e6e;
          margin-left: 1%;
          margin-right: 2%;
          width: 65%;
          display: inline-block;
        }
      }
    }
  }
  .cerAddtableData_num_title {
    text-align: center;
    line-height: 60px;
    width: 100%;
    font-size: 15px;
    font-weight: 100;
  }
  .text_center {
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    background-color: #f1f1f198;
  }
  .add_icon_button {
    margin-right: 20px;
    position: absolute;
    right: 80px;
    top: 15px;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }
  .shspan {
    font-size: 18px;
    border-left: 3px solid rgb(2, 185, 128);
    padding-left: 5px;
    line-height: 18px;
  }
  .add_card {
    width: 96% !important;
    overflow: hidden;
    margin: 0 2% !important;
  }
  .add_card_col {
    width: 100% !important;
    // height: 100%;
    // overflow-x: hidden;
    // overflow-y: auto;
    background-color: #fff;
  }
  .el_add_card_hover {
    border: none !important;
    box-shadow: none !important;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .add_leftnav {
    width: 160px;
    margin-right: 20px;
    float: left;
    position: fixed;
    top: 140px;
    height: 100%;
    ul {
      margin-left: 20px;
      width: 140px;
      border-right: #ccc 1px solid;
      li {
        height: 26px;
        border-radius: 4px;
        margin-top: 10px;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .add_leftnav_bgcolor {
    background-color: rgba(33, 108, 103, 0.274);
  }
  .preservation {
    margin-left: 80px;
    margin-top: 18px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .details_preservation {
    margin-left: 10px;
    margin-top: 18px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .add_rightbody {
    width: 73.5%;
    height: 100%;
    float: left;
    margin-left: 170px;
  }
  .add_rightbody_two {
    width: 73.5%;
    height: 100%;
    float: left;
    margin-left: 65px;
  }
  .add_title_details {
    font-size: 23px;
    padding-left: 25px;
    line-height: 55px;
    .navname {
      font-size: 14px;
      background-color: rgb(10, 138, 85);
      color: #fff;
      display: inline-block;
      line-height: 24px;
      padding: 0 4px;
      position: relative;
      top: -2px;
      left: -10px;
      border-radius: 4px;
    }
  }

  .add_head {
    // margin-top: 65px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    box-shadow: -1px 1px 4px #ccc;
    position: sticky;
    top: 0px;
    z-index: 2;
  }

  .icon-suofang,
  .icon-suofang1 {
    left: 160px;
    font-size: 18px;
    cursor: pointer;
  }

  .el-form {
    width: 44%;
    float: left;
    margin-left: 1%;
    margin-right: 5%;
    margin-bottom: 5px;
  }
  .el_add_card {
    margin-top: 16px;
  }
  .el-table__row {
    cursor: pointer !important;
  }
  /deep/.el-form-item--small .el-form-item__error {
    left: unset;
    right: 0;
  }

  /deep/ .has-gutter {
    tr {
      font-size: 12px;
      font-weight: 200 !important;
    }
    .cell {
      font-weight: 500;
    }
  }
  .top_heder {
    padding-top: 50px;
    padding-bottom: 50px;
    .top_heder_box {
      display: flex;
      justify-content: space-between;
      width: 40%;
      margin: 0 auto;
      padding-top: 10px;
      .top_heder_box_left {
        display: flex;
        width: 40%;
        span {
          font-size: 12px;
          width: 120px;
          line-height: 28px;
          text-align: right;
        }
      }
      .top_heder_box_right {
        display: flex;
        width: 40%;
        span {
          font-size: 12px;
          width: 120px;
          line-height: 28px;
          text-align: right;
        }
      }
    }
    .top_heder_box2 {
      width: 0%;
      margin: 0 auto;
      padding-top: 20px;
    }
  }
  .heder {
    display: flex;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 13px;
    justify-content: space-between;
    .heder_left {
      display: flex;
      .titleby:hover {
        background: white !important;
        color: rgb(255, 106, 0) !important;
        cursor: pointer;
      }
      .titleby {
        margin: 0 10px;
      }
    }
    .heder_right {
      padding-right: 20px;
      display: flex;
      /deep/ .el-button {
        margin-right: 20px;
      }
    }
  }
  /deep/ .el-button--text {
    color: #525252;
    font-size: 12px;
  }
  /deep/ .el-table td {
    padding: 6px 0;
    font-size: 12px;
  }
  /deep/ .el-button--primary {
    background-color: #0a8a55;
    border: #0a8a55;
  }
  /deep/ .cell {
    .el-button {
      color: #143852;
      padding: 0px;
    }
  }

  .leftMask {
    .msg_header {
      overflow: hidden;
      width: 100%;
      height: 70px;
      background-color: #f6f9fc;
      .msg_head {
        position: relative;
        margin-top: 15px;
        width: 100%;
        height: 40px;
        display: flex;
        // .el-icon-s-order {
        //   text-indent: 15px;
        //   font-size: 40px;
        //   color: #3e84e9;
        // }
        .msg_title_name {
          width: 100%;
        }
        .msg_title_name {
          position: absolute;
          top: 0px;
          left: 20px;
          line-height: 40px;
          font-size: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: flex;
          p {
            width: 90%;
          }
        }
      }
    }
    .msg_nav {
      height: 40px;
      background-color: #f0f4f9;
      border-bottom: 2px solid #e4e7ed;
      /deep/ .el-tabs__nav {
        .el-tabs__item {
          padding: 0 20px;
          width: 100px !important;
          text-align: center;
        }

        .el-tabs__active-bar {
          width: 60px !important;
          background-color: #02b980;
        }
      }
      .msg_nav_content {
        width: 100%;
        overflow: hidden;
        .el-tabs__header {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          position: relative;
          padding-top: 10px;
          width: 100%;
          margin: 0 0 0 0px;
        }
        .el-tabs__header {
          padding: 0;
          position: relative;
          margin: 0 0 15px;
        }
        .el-tabs__header {
          margin: 0 0 0 -2px !important;
          padding-top: 0px !important;
        }
        .el-tabs__content {
          overflow: hidden;
          position: relative;
        }
        .el-tabs__item.is-active {
          background-color: #f0f4f9;
        }
        .el-tabs__item {
          padding: 0 20px;
          height: 40px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          line-height: 40px;
          display: inline-block;
          list-style: none;
          font-size: 14px;
          font-weight: 500;
          color: #303133;
          position: relative;
          border-bottom: 2px solid #f0f4f9;
        }
        .el-main {
          padding: 0 !important;
          li {
            list-style: none;
          }
          // width: calc(100% - 20px) !important;
          color: #333;
          .el-collapse {
            background-color: white;
          }
          .el-collapse-item {
            background-color: white;
          }
          .el-scrollbar__view {
            margin-bottom: 50px;
            background-color: white;
            .card {
              font-size: 13px;
              padding-bottom: 300px;
            }
            .el-collapse {
              border-top: none;
            }
          }
          .main_content {
            float: left;
            width: 100%;
            min-height: 30px;
            border-bottom: 1px solid #f2f2f2;
            li {
              padding-left: 15px;
            }
            .main_left {
              margin-right: 5%;
              float: left;
              width: 45%;
              .list {
                position: relative;
                margin-top: 10px;
                margin-bottom: 0px;
                min-height: 30px;
                .span {
                  position: absolute;
                  left: 0px;
                  line-height: 30px;
                  color: #555;
                }
                .list_group {
                  margin-left: 130px;
                  height: auto;
                  line-height: 30px;
                  color: #000;
                  word-wrap: break-word;
                  word-break: normal;
                }
              }
            }
            .main_right {
              float: left;
              width: 45%;
              .list {
                position: relative;
                margin-top: 10px;
                margin-bottom: 0px;
                min-height: 30px;
                /* border-bottom: 1px solid #F2F2F2; */
                .span {
                  position: absolute;
                  left: 0px;
                  line-height: 30px;
                  color: #555;
                }
                .list_group {
                  top: 10px;
                  margin-left: 100px;
                  height: auto;
                  line-height: 30px;
                  color: #000;
                  word-wrap: break-word;
                  word-break: normal;
                }
              }
            }
          }
        }
      }
    }
    .el-collapse-item__header {
      background: #ffffff;
    }
    .el-scrollbar__view {
      margin-bottom: 50px;
      .card {
        position: relative;
        margin-top: 10px;
        float: left;
        width: 100%;
        font-size: 13px;
        ::-webkit-scrollbar {
          width: 0 !important;
        }
        ::-webkit-scrollbar {
          width: 0 !important;
        }
        .card_list {
          width: 100%;
          position: relative;
          float: left;
          // margin-bottom: 20px;
          border-top: 5px solid #eeeeee;
          // padding: 10px;
          width: calc(100% - 24px);
          height: auto;
          padding-left: 20px;
          // background-color: #eee;
          .card_head {
            position: relative;
            width: 15%;
            line-height: 40px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            font-size: 16px;
            font-weight: bold;
          }
          .main_content {
            float: left;
            width: 100%;
            min-height: 30px;
          }
        }
        .card_list:first-of-type {
          border-top: none;
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-scrollbar {
  height: 100%;
}
.el-scrollbar__wrap {
  overflow: scroll;
  overflow-x: auto;
  overflow-y: auto;
}
.el-tabs__item.is-active {
  color: #000 !important;
}
.el-tabs__item:hover {
  color: #000 !important;
}
</style>



